.header {
  background-color: #333;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-family: Cambria, serif;
}

.logo {
  font-size: 1.5em;
  color: #fff;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logo-image-long {
  max-width: 10%;
  height: 5%;
  /* Adjust the spacing as needed */
}

.nav a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
}

.nav a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .nav {
    margin-top: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
  }
  .logo-image-long {
    max-width: 30%;
    height: 70%;
    /* Adjust the spacing as needed */
  }
  .header {
    padding: 10px;
    font-size: 10px;
  }
}
