.hero {
  background: url('hero-bg.jpg') no-repeat center center/cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: Cambria, serif;
}

.hero-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  color: #000000;

}

.hero-button {
  background-color: #000000;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
}

.logo-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px; /* Adjust the spacing as needed */
}

.hero-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .hero {
    padding: 0 20px;
  }
}
