.contact {
  padding: 40px 20px;
  background-color: #f9f9f9;
  font-family: Cambria, serif;
}

.contact-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.contact-content h2 {
  margin-bottom: 20px;
}

.contact-content p {
  color: #666;
  line-height: 1.6;
}

.contact-content a {
  color: #000000;
  text-decoration: none;
}

