.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer p {
  margin: 0;
}
