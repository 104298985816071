.services {
  background-color: #f4f4f4;
  padding: 50px 20px;
  font-family: Cambria, serif;
}

.services h2 {
  margin-bottom: 30px;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin: 10px;
  box-sizing: border-box;
}

.services_logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 2%;
  border: 5px solid rgba(255, 255, 255, 0.6);
}

.service-card h3 {
  margin-bottom: 10px;
}

.service-card p {
  color: #666;
}

@media (max-width: 768px) {
  .service-card {
    width: 45%;
  }
  .services {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .service-card {
    width: 100%;
  }
  .services {
    padding: 0;
  }
}
