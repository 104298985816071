.about {
  padding: 100px 350px;
  font-family: Cambria, serif;
}

.about h2 {
  margin-bottom: 20px;
}

.about p {
  color: #666;
  line-height: 1.6;
  padding-bottom: 120px;
}
.about_image {
  max-width: 50%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 2%;
  border: 5px solid rgba(255, 255, 255, 0.6);
}

