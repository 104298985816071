body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #333;
  color: white;
}

.nav a {
  margin: 0 15px;
  color: white;
  text-decoration: none;
}

.hero {
  height: 100vh;
  background: url('hero-bg.jpg') no-repeat center center/cover;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.hero-content {
  text-align: center;
}

.hero-button {
  padding: 10px 20px;
  background: #000000;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}

.services, .about {
  padding: 50px 250px;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service-card {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  margin: 10px;
  box-sizing: border-box;
}

.footer {
  background: #333;
  color: white;
  padding: 10px 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .nav a {
    margin: 0 10px;
  }

  .service-card {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .nav {
    display: flex;
  }
  .services, .about {
    padding: 10px;
  }

  .service-card {
    width: 100%;
  }
  .services, .about {
    padding: 10px;
  }
}
